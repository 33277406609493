import { render, staticRenderFns } from "./BaseFooter.vue?vue&type=template&id=d391f1c8&scoped=true&"
import script from "./BaseFooter.vue?vue&type=script&lang=ts&"
export * from "./BaseFooter.vue?vue&type=script&lang=ts&"
import style0 from "./BaseFooter.vue?vue&type=style&index=0&id=d391f1c8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d391f1c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThreesidesLogo: require('/codebuild/output/src2341730891/src/threease_lp/components/logo/ThreesidesLogo.vue').default,FooterNavList: require('/codebuild/output/src2341730891/src/threease_lp/components/FooterNavList.vue').default,BaseMaxWidthContainer: require('/codebuild/output/src2341730891/src/threease_lp/components/shared/Base/BaseMaxWidthContainer.vue').default})
