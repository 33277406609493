import { render, staticRenderFns } from "./default.vue?vue&type=template&id=40b38d69&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=40b38d69&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b38d69",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeader: require('/codebuild/output/src2341730891/src/threease_lp/components/BaseHeader.vue').default,BaseFooter: require('/codebuild/output/src2341730891/src/threease_lp/components/BaseFooter.vue').default})
